import React, { useState } from "react"
import "./ErrorBox.scss"

function ErrorBox({ title, desc = "", onPress = () => { } }) {

    const [IsOpen, setIsOpen] = useState(true)
    if (!title) return <></>

    function toggle(e) {
        // if (IsOpen) { onPress && onPress() }
        // e.preventDefault()
        // setIsOpen(!IsOpen)
    }

    return (
        <tr>
            <td className="title">{title}</td>
            <td className="desc">
                {desc?.split("\n").map((line, i) => (
                    <React.Fragment key={i}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </td>
        </tr>
    )
}
export default ErrorBox