
import { useFetchFromStrapi } from "../../../../hooks/useFetchFromStrapi";
import { EmailList } from "./EmailList";

/**
 * Component to display faulty emails.
 */
export function FaultyEmails({ pageSize = 20 }) {
    // Query for emails with errors.
    const queryConfig = {
        sort: "headerDate:DESC",
        filters: {
            type: { $eq: "PRESS_RELEASE" },
            article: { id: { $null: true } },
            progressErrorTitle: { $null: false } // Only emails with an error title.
        },
        pagination: {
            page: 1,
            pageSize: pageSize
        }
    };

    const { data: emails, loading, error } = useFetchFromStrapi("/mails", queryConfig);

    if (loading) return <p>Loading faulty emails...</p>;
    if (error) return <p>Error loading faulty emails.</p>;

    return <EmailList title="Faulty Emails" emails={emails} />;
}
