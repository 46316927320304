// HomePage.js
import React, { useEffect, useState } from "react";
import ArticleCount from "../../components/atoms/ArticleCount/ArticleCount";
import { FaultyEmails } from "../../components/molecules/lists/email/FaultyEmails";
import { LatestEmails } from "../../components/molecules/lists/email/LatestEmails";
import { QuickLinks } from "../../components/molecules/sidebar/QuickLinks/QuickLinks";
import { StatsCard } from "../../components/molecules/sidebar/StatsCard/StatsCard";
import { UserInfo } from "../../components/molecules/sidebar/UserInfo/UserInfo";
import CurrentProcess from "../../container/CurrentProcess";
import { Strapi } from "../../functions/Strapi";
import { useFetchData } from "../../hooks/useFetchData";
import "./HomePage.scss";

function HomePage() {
    // Dummy-Zustände
    const [loginName, setLoginName] = useState("Admin");
    const [hasToken, setHasToken] = useState(true);
    const [stats, setStats] = useState({ Nutzer: 100, Artikel: 50, Fehler: 5 });
    const [errors, setErrors] = useState([]);

    // Dummy Quick Links
    const quickLinks = [
        { name: "UptimeKuma", url: "http://116.202.115.23:36111/" },
        { name: "datahub", url: "https://datahub.diestadt.app/admin/content-manager/collection-types/api::article.article?page=1&pageSize=50&sort=id:DESC" },
        { name: "searchconsole", url: "https://search.google.com/search-console?hl=de&resource_id=sc-domain:mittelhessen.app" },
        { name: "openai usage", url: "https://platform.openai.com/settings/organization/usage" },
    ];

    // Dummy-Fehlerliste
    useEffect(() => {
        setErrors([
            { id: 1, message: "Fehler 1" },
            { id: 2, message: "Fehler 2" },
        ]);
    }, []);

    // Abrufen der Crawler-Daten via Hook (Endpoint ggf. anpassen)
    const { data: crawlerData, loading: crawlerLoading } = useFetchData(
        Strapi.API_ADDRESS + "/current-article?populate[0]=mail&populate[1]=mail.sender",
        { headers: Strapi.DEFAULT_HEADERS, method: "GET" },
        5000
    );

    const containerClass = "container-fluid HomePage";
    const rowClass = "row";
    const fullCol = "col-12 p-1";
    const responsiveCol = "col-12 col-md-4 col-xl-3 col-xxl-2 p-1";
    const paddedCard = "card p-4";
    const cardClass = "card";

    return (
        <div className={containerClass}>
            {/* Benutzerinformationen */}
            <div className={rowClass}>
                <div className={fullCol}>
                    <div className={paddedCard}>
                        <UserInfo />
                    </div>
                </div>
            </div>

            {/* Statistiken und Fehler */}
            <div className={`${rowClass} mb-3 keyValueBoxes`}>
                <div className={responsiveCol}>
                    <StatsCard stats={stats} />
                </div>
                <div className={responsiveCol}>
                    <FaultyEmails errors={errors} pageSize={5} />
                </div>
                <div className={responsiveCol}>
                    <div className={cardClass}>
                        <div className="card-header">Article count</div>
                        <span className="text-center">
                            <ArticleCount />
                        </span>
                    </div>
                </div>
                <div className={responsiveCol}>
                    <LatestEmails errors={errors} pageSize={5} />
                </div>
                <div className={responsiveCol}>
                    <div className={cardClass}>
                        <div className="card-header">Generator Status</div>
                        <div className="card-body">
                            {crawlerLoading ? <p>Lädt...</p> : <CurrentProcess data={crawlerData} />}
                        </div>
                    </div>
                </div>
                <div className={responsiveCol}>
                    <QuickLinks links={quickLinks} />
                </div>
            </div>
        </div>
    );
}

export default HomePage;
