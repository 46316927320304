// EmailListErrors.js
import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { EmailItem } from '../components/molecules/EmailItem';
import { Strapi } from '../functions/Strapi';
import "./EmailListErrors.scss";

const EmailListErrors = ({ currentMailID }) => {
    // Standardfilter "all" bedeutet: Alle Mails mit progressErrorTitle (alle Fehler)
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filterTitle, setFilterTitle] = useState("all");

    const ERROR_FILTER_OPTIONS = [
        "HandleWebsite Error",
        "Logikfehler",
        "Fehler",
        "Dokument nicht verarbeitet",
        "Keine Sources"
    ];
    const PAGE_SIZE = 100;

    // Lädt Fehler anhand von Seite und Filter
    const loadErrors = async (pageNumber = 1) => {
        // Basisquery: nur PRESS_RELEASE-Mails ohne Artikel und mit Fehler
        const query = {
            sort: "headerDate:DESC",
            filters: {
                type: { $eq: "PRESS_RELEASE" },
                article: { id: { $null: true } },
                progressErrorTitle: { $null: false } // Nur Mails mit Fehler
            },
            pagination: {
                page: pageNumber,
                pageSize: PAGE_SIZE
            }
        };

        // Falls ein spezieller Filter gewählt wurde (nicht "all")
        if (filterTitle !== "all") {
            if (filterTitle === "rest") {
                // "Restliche": Fehler, die nicht in den bekannten Optionen sind
                query.filters.progressErrorTitle = { $notIn: ERROR_FILTER_OPTIONS };
            } else {
                // Spezifischer Fehlerfilter (case-insensitiv)
                query.filters.progressErrorTitle = { $eqi: filterTitle };
            }
        }

        const queryString = qs.stringify(query, { encodeValuesOnly: true });

        try {
            setLoading(true);
            const response = await axios({
                url: Strapi.API_ADDRESS + "/mails?" + queryString,
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            const fetched = response.data?.data || [];
            // Ersetzt bei Seite 1 oder hängt an
            setArticles(prev => pageNumber === 1 ? fetched : [...prev, ...fetched]);
            setCount(response?.data?.meta?.pagination?.total || 0);
            setHasMore(fetched.length === PAGE_SIZE);
            setPage(pageNumber);
        } catch (error) {
            console.error("Fehler beim Abrufen der Daten:", error);
        } finally {
            setLoading(false);
        }
    };

    // Lädt Daten neu bei Filterwechsel
    useEffect(() => {
        loadErrors(1);
    }, [filterTitle]);

    // Infinite Scroll: Lädt weitere Seiten beim Scrollen
    useEffect(() => {
        const handleScroll = () => {
            if (loading || !hasMore) return;
            if (
                window.innerHeight + document.documentElement.scrollTop + 100 >=
                document.documentElement.offsetHeight
            ) {
                loadErrors(page + 1);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [loading, hasMore, page, filterTitle]);

    // Entfernt Fehler aus einer Mail
    const removeErrorFromMail = (mailId) => {
        const apiToken = localStorage.getItem("strapiApiKey");
        if (!apiToken) throw new Error("API Token nicht gefunden");

        return axios({
            url: `https://datahub.diestadt.app/api/mails/${mailId}`,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiToken}`
            },
            data: {
                data: {
                    progressError: null,
                    progressErrorTitle: null
                }
            }
        })
            .then(() => { })
            .catch(error => {
                console.error("Fehler beim Entfernen des Fehlers:", error);
                throw new Error("Fehler beim Entfernen des Fehlers: " + error.message);
            });
    };

    // Entfernt alle Fehler im System (über alle Seiten hinweg) nach Bestätigung
    const handleRemoveAllErrors = async () => {
        if (window.confirm("Möchten Sie wirklich ALLE Fehler entfernen?")) {
            try {
                let errorIds = [];
                let currentPage = 1;
                let totalPages = 1;

                // Schleife über alle Seiten, um alle Fehler-IDs zu sammeln
                do {
                    const query = {
                        sort: "headerDate:DESC",
                        filters: {
                            type: { $eq: "PRESS_RELEASE" },
                            article: { id: { $null: true } },
                            progressErrorTitle: { $null: false } // Nur Mails mit Fehler
                        },
                        pagination: {
                            page: currentPage,
                            pageSize: PAGE_SIZE
                        }
                    };

                    if (filterTitle !== "all") {
                        if (filterTitle === "rest") {
                            query.filters.progressErrorTitle = { $notIn: ERROR_FILTER_OPTIONS };
                        } else {
                            query.filters.progressErrorTitle = { $eqi: filterTitle };
                        }
                    }

                    const queryString = qs.stringify(query, { encodeValuesOnly: true });
                    const response = await axios({
                        url: Strapi.API_ADDRESS + "/mails?" + queryString,
                        headers: Strapi.DEFAULT_HEADERS,
                        method: "GET"
                    });

                    const fetched = response.data?.data || [];
                    // IDs der aktuellen Seite anhängen
                    errorIds = [...errorIds, ...fetched.map(item => item.id)];
                    totalPages = response.data?.meta?.pagination?.pageCount || 1;
                    currentPage++;
                } while (currentPage <= totalPages);

                // Entfernt alle Fehler parallel
                await Promise.all(errorIds.map(id => removeErrorFromMail(id)));
                console.log("Alle Fehler entfernt");
                // Aktualisiert die Anzeige
                loadErrors(1);
            } catch (error) {
                console.error("Fehler beim Entfernen aller Fehler:", error);
                alert("Fehler beim Entfernen aller Fehler.");
            }
        }
    };

    // Berechnungen: Dauer und Kosten
    const timePerArticle = 26;
    const pricePerArticle = 0.04;
    const totalMinutes = Math.round((count * timePerArticle) / 60);
    const costs = Math.round(pricePerArticle * count);

    return (
        <div className="p-3 EmailListErrors">
            <h2 className="text-lg font-semibold mb-2">Fehler ({count})</h2>
            <span>
                Dauer: ca. {totalMinutes} min. | Kosten ca. {costs}$
            </span>
            <br />
            {/* Filterauswahl */}
            <select
                value={filterTitle}
                onChange={(e) => setFilterTitle(e.target.value)}
            >
                <option value="all">Alle Fehler</option>
                {ERROR_FILTER_OPTIONS.map((option, idx) => (
                    <option key={idx} value={option}>
                        {option}
                    </option>
                ))}
                <option value="rest">Restliche</option>
            </select>
            <br />
            <button onClick={handleRemoveAllErrors} className="remove-all-button">
                Alle Fehler löschen
            </button>
            {articles.map(item => (
                <EmailItem
                    {...item}
                    key={item.id}
                    currentMailID={currentMailID}
                />
            ))}
            {loading && <p>Lädt...</p>}
        </div>
    );
};

export default EmailListErrors;
