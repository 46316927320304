export function StatsCard({ stats }) {
    return (
        <div className="card">
            <div className="card-header">Aktuelle Zahlen</div>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    {Object.entries(stats).map(([key, value]) => (
                        <li key={key} className="list-group-item">
                            {key}: {value}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}