export function QuickLinks({ links }) {
    return (
        <div className="card">
            <div className="card-header">Quick Links</div>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    {links.map((link, index) => (
                        <li key={index} className="list-group-item">
                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                {link.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}