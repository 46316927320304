// App.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { Strapi } from './functions/Strapi';
import ArticleCorrectionPage from './pages/ArticleCorrectionPage/ArticleCorrectionPage';
import ArticleErrorPage from './pages/ArticleErrorPage/ArticleErrorPage';
import ArticlePage from './pages/ArticlePage';
import BaustellenPage from './pages/BaustellenPage/BaustellenPage';
import EventsPage from './pages/EventsPage';
import HomePage from './pages/HomePage/HomePage';
import TokensPage from './pages/TokensPage';

// Component to close mobile menu on route change
const RouteChangeHandler = ({ closeMenu }) => {
  const location = useLocation();
  useEffect(() => {
    closeMenu(); // close mobile menu when location changes
  }, [location]);
  return null;
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [errorCount, setErrorCount] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  // Fetch error count from API
  const fetchData = async () => {
    try {
      const response = await axios({
        url: Strapi.API_ADDRESS +
          "/mails?sort=headerDate:DESC&filters[type][$eq]=PRESS_RELEASE&filters[article][id][$null]=true&filters[progressErrorTitle][$null]=false",
        headers: Strapi.DEFAULT_HEADERS,
        method: "GET"
      });
      setErrorCount(response?.data?.meta?.pagination?.total);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, []);

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiryDate');
    alert("Sorry to see you go.");
    window.location.reload();
  };

  // Toggle mobile menu
  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Reusable Menu Component
  const Menu = () => (
    <div className="Menu card h-100 mt-0 p-0">
      <ul className="list-group h-100">
        <li className="list-group-item"><strong>General</strong></li>
        <li className="list-group-item">
          <NavLink exact to="/" activeClassName="active" className="nav-link">Home</NavLink>
        </li>

        <li className="list-group-item mt-3"><strong>Events</strong></li>
        <li className="list-group-item">
          <NavLink to="/events" activeClassName="active" className="nav-link">Events</NavLink>
        </li>

        <li className="list-group-item mt-3"><strong>Articles</strong></li>
        <li className="list-group-item">
          <NavLink to="/articles" activeClassName="active" className="nav-link">Queue</NavLink>
        </li>
        <li className="list-group-item">
          <NavLink to="/articles/corrections" activeClassName="active" className="nav-link">Corrections</NavLink>
        </li>
        <li className="list-group-item">
          <NavLink to="/articles/errors" activeClassName="active" className="nav-link">
            Errors {!loading && `(${errorCount})`}
          </NavLink>
        </li>

        <li className="list-group-item mt-3"><strong>Advanced</strong></li>
        <li className="list-group-item">
          <NavLink to="/baustellen" activeClassName="active" className="nav-link">Construction</NavLink>
        </li>
        <li className="list-group-item">
          <NavLink to="/tokens" activeClassName="active" className="nav-link">Tokens</NavLink>
        </li>

        <li className="list-group-item mt-3"><strong>External</strong></li>
        <li className="list-group-item">
          <a href="https://datahub.diestadt.app/admin" target="_blank" rel="noopener noreferrer" className="nav-link">
            Datahub
          </a>
        </li>
        <li className="list-group-item">
          <a href="https://mittelhessen.app/neuigkeiten" target="_blank" rel="noopener noreferrer" className="nav-link">
            News Landing
          </a>
        </li>

        <li className="list-group-item mt-3">
          <button onClick={handleLogout} className="btn btn-link p-0">Logout</button>
        </li>
      </ul>
    </div>
  );

  return (
    <Router>
      {/* Closes menu on route change */}
      <RouteChangeHandler closeMenu={() => setMenuOpen(false)} />
      <div className="App">
        {/* Header with logo and mobile menu toggle */}
        <header className="d-flex justify-content-center align-items-center p-3 border-bottom">

          {/* Logo and title */}
          <div className="d-flex flex-column align-items-center text-center">
            <div className="d-flex align-items-center">
              <img src="./assets/images/laraleyla_logo.png" alt="Logo" className="logo me-2" />
            </div>
            <span>Admin Monitor</span>
          </div>
          {/* Toggle button for mobile view */}
          <button className="btn d-md-none" onClick={toggleMenu}>
            {menuOpen ? 'Close Menu' : 'Open Menu'}
          </button>
        </header>

        <div className="row h-100">
          {/* Sidebar for desktop */}
          <div className="col-md-2 d-none d-md-block h-100">
            <Menu />
          </div>

          {/* Mobile Menu */}
          {menuOpen && (
            <div className="col-12 d-md-none mb-3">
              <Menu />
            </div>
          )}

          {/* Main Content */}
          <div className={menuOpen ? "col-12" : "col-md-10"}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/tokens" element={<TokensPage />} />
              <Route path="/baustellen" element={<BaustellenPage />} />
              <Route path="/articles" element={<ArticlePage />} />
              <Route path="/articles/errors" element={<ArticleErrorPage />} />
              <Route path="/articles/corrections" element={<ArticleCorrectionPage />} />
              <Route path="*" element={<h1>404</h1>} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
