// EmailList.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ErrorBox from '../components/ErrorBox';
import { TimeAgo } from '../components/TimeAgo';
import { Strapi } from '../functions/Strapi';
import "./EmailList.scss";

export const QueryMailsList = ({ currentMailID }) => {

    const [Article, setArticle] = useState([])
    const [Loading, setLoading] = useState(true)
    const [Count, setCount] = useState(0)

    const fetchData = async () => {
        try {
            const response = await axios({
                url: Strapi.API_ADDRESS + "/mails?sort=headerDate:DESC&filters[type][$eq]=PRESS_RELEASE&filters[article][id][$null]=true&filters[progressErrorTitle][$null]=true",
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            setArticle(response.data?.data);
            setCount(response?.data?.meta?.pagination?.total)
            setLoading(false)
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    if (Loading) return <p>Lädt...</p>

    let timePerArticle = 5 // 5 Sek
    let pricePerArticle = 0.02//0.0017699115044//0.0259090909 // 0.04
    let time = formatDuration(Count * timePerArticle / 60)
    let costs = Math.round(pricePerArticle * Count)

    return (
        <div className='p-3 EmailList'>
            <h2 className="text-lg font-semibold mb-2">Warteschlange ({Count})</h2>
            <span>Dauer: {time} | Kosten ca. {costs}$</span>
            <br />
            {Article.map((i, k) => <EmailItem {...i} key={k} currentMailID={currentMailID} />)}
        </div>
    );
};

function formatDuration(durationInMinutes) {
    let totalMinutes = Math.ceil(durationInMinutes);

    let days = Math.floor(totalMinutes / (24 * 60));
    totalMinutes -= days * 24 * 60;

    let hours = Math.floor(totalMinutes / 60);
    hours = Math.min(hours, 32); // Stunden auf maximal 32 begrenzen
    totalMinutes -= hours * 60;

    let minutes = totalMinutes;

    // Führende Nullen hinzufügen
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${days}T ${hours}h ${minutes}m`;
}

function EmailItem({ id, attributes, currentMailID }) {
    let { subject, headerDate, text, progressError, progressErrorTitle } = attributes
    let strapiUrl = "https://datahub.diestadt.app/admin/content-manager/collection-types/api::mail.mail/" + id

    let isActive = currentMailID == id

    return <a href={strapiUrl} target='_blank'>
        <div className={'item ' + (isActive && " isActive")}>
            <span className='title' title={subject}>{subject}</span>
            <TimeAgo>{headerDate}</TimeAgo>

            <ErrorBox title={progressErrorTitle} desc={progressError} />
        </div>
    </a>
}
