import axios from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import { Strapi } from "../functions/Strapi";
/**
 * Custom hook to fetch data from Strapi.
 * @param {string} route - Strapi API route (e.g., "/mails").
 * @param {object} queryConfig - Query configuration object.
 * @param {number} refreshInterval - Optional refresh interval in ms.
 * @returns {object} - { data, loading, error, refetch }
 */
export const useFetchFromStrapi = (route, queryConfig = {}, refreshInterval = 0) => {
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(0)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch data from Strapi
    const fetchData = async () => {
        setLoading(true);
        setError(null);
        const queryString = qs.stringify(queryConfig, { encodeValuesOnly: true });
        console.log(Strapi.API_ADDRESS + route + "?" + queryString)
        console.log(queryConfig)
        try {
            const response = await axios({
                url: Strapi.API_ADDRESS + route + "?" + queryString,
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            setLimit(response.data?.meta?.pagination?.total)
            setData(response.data?.data || []);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        let interval;
        if (refreshInterval > 0) {
            interval = setInterval(fetchData, refreshInterval);
        }
        return () => interval && clearInterval(interval);
    }, [route, JSON.stringify(queryConfig), refreshInterval]);

    return { data, loading, error, limit, refetch: fetchData };
};
