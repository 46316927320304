class Strapi {
    static MAIN_ADDRESS = "https://datahub.diestadt.app";

    static API_ADDRESS = Strapi.MAIN_ADDRESS + "/api";

    static get DEFAULT_HEADERS() {
        return {
            'Authorization': `Bearer ${localStorage.getItem('strapiApiKey') || ''}`,
            'Content-Type': 'application/json'
        };
    }
}

module.exports = {
    Strapi
};