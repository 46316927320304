import axios from "axios";
import { useState } from "react";
import ErrorBox from "../ErrorBox";
import { TimeAgo } from "../TimeAgo";

export function EmailItem({ id, attributes, currentMailID }) {
    const [Deleted, setDeleted] = useState(false);
    const { subject, headerDate, progressError, progressErrorTitle } = attributes;
    const strapiUrl = `https://datahub.diestadt.app/admin/content-manager/collection-types/api::mail.mail/${id}`;

    const isActive = currentMailID === id;

    const handlePress = async () => {
        if (true) {
            // if (window.confirm("Fehler entfernen?")) {
            try {
                await removeErrorFromMail(id);
                console.log('Fehler entfernt: Mail-ID_' + id);
                setDeleted(true);
            } catch (error) {
                console.error("Fehler beim Entfernen des Fehlers:", error);
                alert("Fehler beim Entfernen des Fehlers.");
            }
        }
    };

    // Funktion zum Entfernen des Fehlers
    const removeErrorFromMail = (mailId) => {
        const apiToken = localStorage.getItem('strapiApiKey'); // Token aus dem Local Storage holen

        if (!apiToken) {
            throw new Error('API Token nicht gefunden');
        }

        return axios({
            url: `https://datahub.diestadt.app/api/mails/${mailId}`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiToken}`,
            },
            data: JSON.stringify({
                data: {
                    progressError: null,
                    progressErrorTitle: null,
                }
            })

        })
            .then(response => {
                return;
            })
            .catch(error => {
                console.error('Fehler beim Entfernen des Fehlers:', error);
                throw new Error('Fehler beim Entfernen des Fehlers: ' + error.message);
            });
    };

    return (
        <div className={`item ${isActive ? 'isActive' : ''}`} style={{ opacity: Deleted ? 0.2 : 1 }}>
            <a href={strapiUrl} target='_blank' rel='noopener noreferrer'>
                <span className='title' title={subject}>{subject}</span>
            </a>
            {isActive && <span>Wird bearbeitet...</span>}
            <TimeAgo>{headerDate}</TimeAgo>
            <ErrorBox title={progressErrorTitle} desc={progressError} />
            <button onClick={handlePress}>Fehler entfernen</button>
        </div>
    );
}
