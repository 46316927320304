import React, { useEffect, useState } from "react";
import "./CurrentProcess.scss";

function CurrentProcess({ data, hoursThreshold = 1 }) {
    const [showWarning, setShowWarning] = useState(false);
    let { title, startTimestamp, mail } = data || {}
    let { attributes, id } = mail?.data || {}
    let { subject, sender, mailID, text, headerDate } = attributes || {}

    const [IsOpen, setIsOpen] = useState(false)

    useEffect(() => {
        // Überprüfe, ob Daten vorhanden und startTimestamp definiert ist
        if (data && data.startTimestamp) {
            const currentTime = new Date();
            const diff = currentTime - new Date(data.startTimestamp);

            // Überprüfe, ob die Bearbeitungszeit länger als h Stunden ist
            setShowWarning(diff > hoursThreshold * 3 * 60 * 1000);
        }
    }, [data, hoursThreshold]);

    const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    function toggleOpen() {
        setIsOpen(!IsOpen)
    }

    let statusInt = 2
    //Wenn seit unter 1 min bearbeitet wird
    if (new Date().getTime() - new Date(startTimestamp).getTime() < 75 * 1000) {
        statusInt = 0
    } else if (new Date().getTime() - new Date(startTimestamp).getTime() < 3 * 60 * 1000) {
        statusInt = 1
    }

    const statusColor = statusInt === 0 ? "green" : statusInt === 1 ? "yellow" : "red"

    return (
        <div className="CurrentProcess p-3" onClick={toggleOpen}>
            <div className="status mb-3" style={{ backgroundColor: statusColor, width: "100%", height: "64px", borderRadius: "12px" }} title="Status" />
            {data ? (
                <div className="CurrentProcess p-3">
                    <h1>Generierung läuft</h1>
                    <small>Da nun mehrere Artikel parallel generiert werden, sieht man nicht den aktuellen Artikel. Wir arbeiten an einer Lösung.</small>
                </div>
            ) : (
                <div className="CurrentProcess p-3">
                    <h1 className="text-red">Keine Bearbeitung 🥲</h1>
                    <small>Aktuell läuft der Crawler nicht.</small>
                </div>
            )}
        </div>
    );
}

export default CurrentProcess;
