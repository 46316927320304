import React from 'react';

export function UserInfo() {
    const token = localStorage.getItem('strapiApiKey');

    // Zeige nur die ersten und letzten 5 Zeichen, wenn Token vorhanden und länger als 10 Zeichen ist
    const displayToken =
        token && token.length > 10 ? `${token.slice(0, 5)}...${token.slice(-5)}` : token || "Nicht vorhanden";

    const handleCopy = () => {
        if (token) {
            navigator.clipboard.writeText(token);
        }
    };

    return (
        <div>
            <h5>Welcome!</h5>
            <div className='d-flex flex-row'>
                <p>Token status: {displayToken}</p>
                {token && (
                    <button className='' onClick={handleCopy}>Copy</button>
                )}
            </div>
        </div>
    );
}