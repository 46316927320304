import { useFetchFromStrapi } from "../../../../hooks/useFetchFromStrapi";
import { EmailList } from "./EmailList";

/**
 * Component to display the latest emails.
 */
export function LatestEmails({ pageSize = 30 }) {
    // Query for the latest emails.
    const queryConfig = {
        sort: "headerDate:DESC",
        pagination: {
            page: 1,
            pageSize: pageSize
        }
    };

    const { data: emails, loading, error } = useFetchFromStrapi("/mails", queryConfig);

    if (loading) return <p>Loading latest emails...</p>;
    if (error) return <p>Error loading latest emails.</p>;

    return <EmailList title="Latest Emails" emails={emails} />;
}

