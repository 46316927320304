
/**
 * UI template for displaying a list of emails.
 * @param {string} title - The header title.
 * @param {Array} emails - Array of email objects (each should have id, subject, date, preview).
 */
export function EmailList({ title, emails }) {
    return (
        <div className="card">
            {/* Card header showing title and email count */}
            <div className="card-header">
                {title} ({emails.length})
            </div>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    {emails.map(email => (
                        <li key={email.id} className="list-group-item">
                            <div className="email-item">
                                {/* Email header: subject and date */}
                                <div className="email-header">
                                    <strong>{email.subject}</strong>
                                    <span className="email-date">{email.date}</span>
                                </div>
                                {/* Email preview text */}
                                <div className="email-preview">
                                    {email.preview}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
