import axios from "axios";
import { useEffect, useState } from "react";

// Hook zum Abrufen von Daten (optional: in einer eigenen Datei ablegen)
export const useFetchData = (url, config = {}, refreshInterval = 0) => {
    // Zustand für die geladenen Daten, den Lade-Status und Fehler
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Funktion zum Abrufen der Daten
    const fetchData = async () => {
        setLoading(true);
        try {
            // Axios-Aufruf (Antwort anpassen, falls notwendig)
            const response = await axios({ url, ...config });
            setData(response.data?.data?.attributes || response.data);
            setError(null);
        } catch (err) {
            console.error("Fehler beim Laden der Daten:", err);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    // Initialer Aufruf und ggf. Polling bei refreshInterval > 0
    useEffect(() => {
        fetchData();
        let interval;
        if (refreshInterval > 0) {
            interval = setInterval(fetchData, refreshInterval);
        }
        return () => interval && clearInterval(interval);
    }, [url, refreshInterval]);

    return { data, loading, error, refetch: fetchData };
};
