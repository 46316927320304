import React from 'react';
import { useFetchFromStrapi } from '../../../hooks/useFetchFromStrapi';

const ArticleCount = ({
    route = "/articles",
    queryFilters = { ownArticle: { $eq: true } },
}) => {
    const queryConfig = {
        filters: queryFilters,
        pagination: { page: 1, pageSize: 1 }
    };

    const { data, loading, error, limit } = useFetchFromStrapi(
        route,
        { query: queryConfig, method: "GET" },
        0
    );

    if (error) return <p>Error loading count.</p>;

    return (
        <span className="h1 text-primary fw-bold">
            {limit ?? 0}
        </span>
    );
};

export default ArticleCount;
