import React from 'react';
import CurrentProcess from '../container/CurrentProcess';
import FinishList from '../container/FinishList';
import { QueryMailsList } from '../container/QueryMailsList';
import { Strapi } from '../functions/Strapi';
import { useFetchData } from '../hooks/useFetchData';

const ArticlePage = () => {
    // Artikel-Daten alle 5 Sekunden abrufen
    const { data: Data, loading, error } = useFetchData(
        Strapi.API_ADDRESS + "/current-article?populate[0]=mail&populate[1]=mail.sender",
        { headers: Strapi.DEFAULT_HEADERS, method: "GET" },
        5000
    );

    return (
        <div className="container mx-auto p-4">
            <div className="row">
                <div className="col-12 col-lg-3">
                    <QueryMailsList title="Warteschlange" currentMailID={Data?.mail?.data?.id} />
                </div>
                <div className="col-12 col-lg-5">
                    <p>Aktuelle Prozesse:</p>
                    <CurrentProcess data={Data} />
                </div>
                <div className="col-12 col-lg-4">
                    {/* Liste der fertigen Artikel */}
                    <FinishList />
                </div>
            </div>
        </div>
    );
};

export default ArticlePage;
